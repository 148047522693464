<template>
    <p>
        <span v-if="hasReservation">
            Jouw We Are Public-prijs:
            {{ paidPrice ? ('€' + parseTicketPrice(paidPrice)) : 'gratis' }}
            <br>
        </span>

        <span v-else-if="ticketPricing.isFree && !ticketPricing.needsPayment">
            Tickets zijn gratis met de We Are Public-pas
            <br>
        </span>

        <span v-else-if="ticketPricing.needsPayment && ticketPricing.parsedPrice">
            We Are Public-prijs: €{{ ticketPricing.parsedPrice }}
            <br>
        </span>

        <span v-if="ticketPricing.priceRegular">
            Reguliere prijs: €{{ ticketPricing.parsedPriceRegular }}
            <br>
        </span>
    </p>
</template>

<script setup lang="ts">
import type { EventDate } from '~/@types/data';
import { useEventsStore } from '~/store/events';
import { useUserStore } from '~/store/user';

const props = defineProps<{
    event: EventDate;
}>();

const eventsStore = useEventsStore();
const userStore = useUserStore();

const hasReservation = computed(() => userStore.hasReservation(props.event.id));

const ticketPricing = computed(() => eventsStore.ticketPricing);
const paidPrice = computed(() => eventsStore.paidPrice);
</script>
