import { defineStore } from 'pinia';
import type { EventDate } from '~/@types/data';
import { useUserStore } from './user';
import { useAuthStore } from './auth';
import {
    type TicketPricing,
    calculateTicketPricing,
    emptyTicketPricing
} from '~/utils/ticketPricing';
import type { FairUsePolicy_GlobalSet, WaitingList_GlobalSet } from '~/@types/craft-schema';

// events
export const useEventsStore = defineStore('events', {
    state: () => ({
        activeEventId: null as null | number,
        activeEvent: null as null | EventDate,
        startPath: null as null | string,
        myListHomeChanged: 0,
        needsRefresh: 0,
        fairUsePolicy: {
            premium: null as string | null,
            extraCapacity: null as string | null,
            discount: null as string | null,
        },
        waitingListInfo: null as string | null,
        fairUsePolicyUri: '/' as string | null,
        requestPath: null as string | null,
        ticketPricing: emptyTicketPricing as TicketPricing,
        paidPrice: undefined as number | null | undefined,
    }),

    getters: {
        isFullyBooked(): boolean {
            return !!this.activeEvent?.isFullyBooked;
        },

        isWaitingListEnabled(): boolean {
            return this.isFullyBooked && this.activeEvent?.waitingListEnabled === true;
        }
    },

    actions: {
        deactivate() {
            this.activeEvent = null;
            this.activeEventId = null;

            this.unsetTicketPricingData();
        },

        activate(payload: number|string|EventDate) {
            if (typeof payload === 'string') {
                this.activeEventId = parseInt(payload, 10);
            } else if (typeof payload === 'number') {
                this.activeEventId = payload;
            } else if (payload.id) {
                this.activeEventId = payload.id;
            }
        },

        setActiveEventData(payload: EventDate, requestPath: string) {
            this.activeEvent = payload;
            this.requestPath = requestPath;

            this.setTicketPricingData(payload);
        },

        setStartPath(payload: string) {
            this.startPath = payload;
        },

        updateMyListHome() {
            ++this.myListHomeChanged;
        },

        setFairUsePolicy(payload: FairUsePolicy_GlobalSet) {
            if (payload.entry[0]?.uri) {
                this.fairUsePolicyUri = payload.entry[0]?.uri;
            }

            if (payload.richText) {
                this.fairUsePolicy.premium = payload.richText;
            }

            if (payload.richText2) {
                this.fairUsePolicy.extraCapacity = payload.richText2;
            }

            if (payload.richText3) {
                this.fairUsePolicy.discount = payload.richText3;
            }
        },

        setWaitingListInfo(payload: WaitingList_GlobalSet) {
            if (payload.richText) {
                this.waitingListInfo = payload.richText;
            }
        },

        setTicketPricingData(event: EventDate) {
            const authStore = useAuthStore();
            const userStore = useUserStore();

            this.ticketPricing = calculateTicketPricing(
                event,
                authStore.loggedIn,
                userStore.canMakeFreePremiumReservation
            );
        },

        unsetTicketPricingData() {
            this.ticketPricing = emptyTicketPricing;
        }
    }
});
